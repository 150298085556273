import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';


library.add(
	faBars,
	faFacebookF,
	faInstagram,
	faAngleDown
);

dom.watch();
